<template>
	<div class="navigation flex-column">
		<ul>
			<li v-for="(link, index) in links" :key='index' class="nav-item">
				<router-link data-toggle="collapse" data-target=".show" v-if="link.mayAccess" class="nav-link" active-class="active" :to="{name:link.toName,params: getLinkParams(link.params)}">{{link.text}}</router-link>
			</li>
			<li class="nav-item">
				<router-link v-if='backRoute.pageName !== ""' class="nav-link back-button" :to="backRoute.route" >{{$t("navigation.backToPage",{pageName: backRoute.pageName})}}</router-link>
			</li>
			<div v-if="possibleViewingEntities.length > 1" class="change-view">
				<p>Skift visning</p>
				<a v-for="entity in possibleViewingEntities" href="#" :key="entity.value" @click.prevent="setViewingEntity(entity.value)"><template v-if="viewingEntity === entity.value">- </template>{{entity.name}}</a>
			</div>
		</ul>
	</div>
</template>

<script>
	import isGranted from "@/mixin/isGranted";
	import permissions from "@/permissions";
	import {mapState, mapGetters, mapMutations} from "vuex";
	import entityMap from "@/entityMap";
	export default {
		name: "Navigation",
		mixins: [isGranted],
		computed: {
			...mapState('concrete',{
				viewingEntity: 'viewingEntity',
			}),
			...mapGetters('concrete',{
				viewingEntityIRIParts: 'viewingEntityIRIParts',
				viewUsersAsEmployees: 'viewUsersAsEmployees',
				//haulerIriParts: 'haulerIriParts',
				possibleViewingEntities: 'possibleViewingEntities'
			}),
			routeName() {
                return this.$route.name;
            },
            routeParams() {
                return this.$route.params;
			},
			backRoute() {
				let path = this.$route.path;
				let name = this.$route.name;

				let backPath = path.split("/");

				// Fix for pilot userlist back issue
				if((backPath[2] === "districts" || backPath[2] === "companies") && backPath.length === 5){
					// pop 2 extra times so route will go to dashboard
					backPath.pop();
					backPath.pop();
				}
				backPath.pop();

				let pageName = "";
				
				if(name == "entity-list" || name == "parented-entity-list") {
					pageName = this.$t('terms.dashboard').toLocaleLowerCase()
				}else if(name == "booking-view" || name == "booking-add") {
					pageName = this.$tc("entities.booking",2);
				}else if(name == "booking-edit") {
					pageName = this.$tc("entities.booking",1);
				}else if(name == "company-view" || name == "company-add") {
					pageName = this.$tc("entities.company",2);
				}else if(name == "company-edit") {
					pageName = this.$tc("entities.company",1);
				}else if(name == "country-view" || name == "country-add") {
					pageName = this.$tc("entities.country",2);
				}else if(name == "country-edit") {
					pageName = this.$tc("entities.country",1);
				}else if(name == "district-view" || name == "district-add") {
					pageName = this.$tc("entities.district",2);
				}else if(name == "district-edit") {
					pageName = this.$tc("entities.district",1);
				}else if(name == "machine-view" || name == "machine-add") {
					pageName = this.$tc("entities.machine",2);
				}else if(name == "machine-edit") {
					pageName = this.$tc("entities.machine",1);
				}else if(name == "trailer-view" || name == "trailer-add") {
					pageName = this.$tc("entities.trailer",2);
				}else if(name == "trailer-edit") {
					pageName = this.$tc("entities.trailer",1);
				}else if(name == "user-view" || name == "user-add") {
					pageName = this.viewUsersAsEmployees ? this.$tc("terms.employee",2) : this.$tc("entities.user",2);
				}else if(name == "user-edit") {
					pageName = this.viewUsersAsEmployees ? this.$tc("terms.employee",2) : this.$tc("entities.user",1);
				}
				
				return {
					route: { path: backPath.join("/") },
					pageName: pageName
				};
			},

			isEntityBased(){
				return this.viewingEntity !== false;
			},
			viewingEntityUrlPart(){
				return this.isEntityBased ? entityMap[this.viewingEntityIRIParts.entity].endpoint : false ;
			},
			links(){
				return {
					bookings:{
						text:  this.$tc("terms.booking",2),
						mayAccess: this.mayAccessBookingList,
						toName: this.isEntityBased ? 'parented-entity-list' : 'entity-list',
						params: this.isEntityBased ? {entityType:'bookings', parentType: this.viewingEntityUrlPart, id: this.viewingEntityIRIParts.id} : {entityType:'bookings'}
					},
					companies:{
						text: this.$tc("terms.company",2),
						mayAccess: this.mayAccessCompanyList,
						toName: this.isEntityBased ? 'parented-entity-list' : 'entity-list',
						params: this.isEntityBased ? {entityType:'companies', parentType: this.viewingEntityUrlPart, id: this.viewingEntityIRIParts.id} : {entityType:'companies'}
					},
					countries:{
						text: this.$tc("terms.country",2),
						mayAccess: this.mayAccessCountryList,
						toName: this.isEntityBased ? 'parented-entity-list' : 'entity-list',
						params: this.isEntityBased ? {entityType:'countries', parentType: this.viewingEntityUrlPart, id: this.viewingEntityIRIParts.id} : {entityType:'countries'}
					},
					districts:{
						text: this.$tc("terms.district",2),
						mayAccess: this.mayAccessDistrictList,
						toName: this.isEntityBased ? 'parented-entity-list' : 'entity-list',
						params: this.isEntityBased ? {entityType:'districts', parentType: this.viewingEntityUrlPart, id: this.viewingEntityIRIParts.id} : {entityType:'districts'}
					},
					// @deprecated Hauling system is deprecated since version 2
					/*haulers:{
						text: this.isEntityBased ? this.$t("terms.companyInfo") : this.$tc("terms.haulier",2),
						mayAccess: this.mayAccessHaulerList,
						toName: this.isEntityBased ? 'hauler-view' : 'entity-list',
						params: this.isEntityBased ? {id: this.haulerIriParts.id} : {entityType:'haulers'}
					},*/
					invoices:{
						text: this.isEntityBased ? this.$t("terms.companyInfo") : this.$tc("terms.invoice",2),
						mayAccess: this.mayAccessHaulerList,
						toName: this.isEntityBased ? 'hauler-view' : 'entity-list',
						//params: this.isEntityBased ? {id: this.haulerIriParts.id} : {entityType:'invoices'}
						params: {entityType:'invoices'}
					},
					machines:{
						text: this.$tc("terms.machine",2),
						mayAccess: this.mayAccessMachineList,
						toName: this.isEntityBased ? 'parented-entity-list' : 'entity-list',
						params: this.isEntityBased ? {entityType:'machines', parentType: this.viewingEntityUrlPart, id: this.viewingEntityIRIParts.id} : {entityType:'machines'}
					},
					trailers:{
						text: this.$tc("terms.trailer",2),
						mayAccess: this.mayAccessTrailerList,
						toName: this.isEntityBased ? 'parented-entity-list' : 'entity-list',
						params: this.isEntityBased ? {entityType:'trailers', parentType: this.viewingEntityUrlPart, id: this.viewingEntityIRIParts.id} : {entityType:'trailers'}
					},
					users:{
						text: this.viewUsersAsEmployees ? this.$tc("terms.employee",2) : this.$tc("terms.user",2),
						mayAccess: this.mayAccessUserList,
						toName: this.isEntityBased ? 'parented-entity-list' : 'entity-list',
						params: this.isEntityBased ? {entityType:'users', parentType: this.viewingEntityUrlPart, id: this.viewingEntityIRIParts.id} : {entityType:'users'}
					}
				};
			},
			mayAccessBookingList(){
				if(this.isEntityBased === false)
					return this.isGranted(permissions.SCREEN.BOOKING);
				
				return this.isGranted(permissions.SCREEN.BOOKING, this.viewingEntity);
			},
			mayAccessCompanyList(){
				return this.isEntityBased === false && this.isGranted(permissions.SCREEN.COMPANY);
			},
			mayAccessCountryList(){
				return this.isEntityBased === false && this.isGranted(permissions.SCREEN.COUNTRY);
			},
			mayAccessDistrictList(){
				if(this.isEntityBased === false)
					return this.isGranted(permissions.SCREEN.DISTRICT);
				
				return this.isGranted(permissions.SCREEN.DISTRICT, this.viewingEntity);
			},
			mayAccessMachineList(){
				if(this.isEntityBased === false)
					return this.isGranted(permissions.SCREEN.MACHINE);
				
				return this.isGranted(permissions.SCREEN.MACHINE, this.viewingEntity);
			},
			mayAccessTrailerList(){
				if(this.isEntityBased === false)
					return this.isGranted(permissions.SCREEN.TRAILER);

				return this.isGranted(permissions.SCREEN.TRAILER, this.viewingEntity);
			},
			mayAccessUserList(){
				if(this.isEntityBased === false)
					return this.isGranted(permissions.SCREEN.USER);
				
				return this.isGranted(permissions.SCREEN.USER, this.viewingEntity);
			},
			mayAccessHaulerList(){
				if(this.isEntityBased === false)
					return this.isGranted(permissions.SCREEN.HAULER);
				
				return this.isGranted(permissions.SCREEN.HAULER, this.viewingEntity);
			}
		},
		methods: {
			...mapMutations('concrete',{
				setViewingEntity: 'setViewingEntity'
			}),
			getLinkParams(params){
				if(typeof params.lang === 'undefined')
					params.lang = this.$i18n.locale;

				return params;
			}
		},
	};
</script>

<style lang="scss" scoped>
	.navigation{
		ul{
			list-style: none;
			padding: 0;
		}
		.nav-link {
			font-weight: 400;
			color: $white;
			padding: 0.5rem 1.5rem;
			&.router-link-exact-active {
				font-weight: 700;
			}
		}
	}
	.change-view{
		margin-top: 20px;
		p{
			margin-bottom: 10px;
			color: #9e9e9e;
			border-bottom: 1px solid white;
			padding: 0 1.5rem 5px;
		}
		a{
			color: white;
			display: block;
			margin-bottom: 5px;
			padding: 0 1.5rem;
		}
	}
	.back-button{
		position: relative;
		&:before {
			content: "";
			background-image: url("~@/assets/images/back-arrow.svg");
			width: 20px;
			height: 20px;
			display: inline-block;
			background-size: contain;
			/* background-position: 50% 60%; */
			vertical-align: middle;
			position: absolute;
			left: 0px;
			top: 8px;
		}
	}

</style>
