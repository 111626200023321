 <template>
	<div class="layout">
		<div v-if="hasFetchedInitialData === false || isFetchingRequiredApiData === true || hasCheckedPermissions === false" class="super-loader">
			<spinner :size="70"/>
		</div>
		<template v-else>
			<!-- Top nav bar -->
			<navbar />
			<!-- Content -->
			<div class="container-fluid">
				<div class="row">
					<!-- Sidebar -->
					<sidebar>
						<navigation/>
					</sidebar>
					<!-- Main content -->
					<main role="main" class="col-md-9 col-lg-9 col-xl-10 ml-sm-0 10 px-4 mr-xl-auto pb-5">
						<router-view/>
						<page-footer />
					</main>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	import {mapState,mapGetters} from "vuex";
	import Spinner from "@/components/loaders/Spinner";
	import isGranted from "@/mixin/isGranted";
	import Navigation from "@/components/Navigation";
	import permissions from "@/permissions";
	import Sidebar from "@/components/navigation/Sidebar";
	import Navbar from "@/components/navigation/Navbar";
	import PageFooter from "@/components/navigation/PageFooter";
	import requiresApiRequest from "@/mixin/requiresApiRequest";

	export default {
		name: "PermissionDependant",
		components: {Navigation, Spinner, Sidebar, Navbar, PageFooter},
		mixins: [isGranted,requiresApiRequest],
		data(){
			return {
				hasCheckedPermissions: false,
				typePermissions: {
					countries: [permissions.SCREEN.COUNTRY],
					districts: [permissions.SCREEN.DISTRICT]
				}
			}
		},
		computed: {
			...mapState('concrete', {
				hasFetchedInitialData: 'hasFetchedInitialData',
				viewingEntity: 'viewingEntity',
				districts: 'districts',
				companies: 'companies'
			}),
			...mapGetters('concrete', {
				viewingEntityIRIParts: 'viewingEntityIRIParts'
			})
		},
		methods: {
			checkRoutePermissions(){
				this.hasCheckedPermissions = false;
				const permissionsRequired = this.determineRouteRequiredPermissions();
				if(permissionsRequired.length !== 0)
				{
					if((this.viewingEntity !== false && this.isGranted(permissionsRequired,this.viewingEntity)) || this.isGranted(permissionsRequired))
					{
						this.hasCheckedPermissions = true;
					}
					else
					{
						// @todo logout?
						this.$router.push({name:'login',params: {lang: this.$i18n.locale}});
					}
				}
				else
				{
					this.hasCheckedPermissions = true;
				}
			},
			determineRouteRequiredPermissions(){
				let permissions = [];
				if(typeof this.$route.meta !== 'undefined' && typeof this.$route.meta.permissions !== 'undefined')
				{
					permissions = permissions.concat(this.$route.meta.permissions);
				}
				
				
				if(typeof this.$route.params.entityType !== 'undefined' && typeof this.typePermissions[this.$route.params.entityType] !== 'undefined')
				{
					permissions = permissions.concat(this.typePermissions[this.$route.params.entityType]);
				}
				
				return permissions;
			},
			ensureViewingEntity()
			{
				if((this.viewingEntityIRIParts.entity === 'Company' && typeof this.companies[this.viewingEntityIRIParts.id] === 'undefined') || (this.viewingEntityIRIParts.entity === 'District' && typeof this.districts[this.viewingEntityIRIParts.id] === 'undefined'))
				{
					this.addRequiredApiRequest({type: 'generic',payload: {entity: {'id': this.viewingEntityIRIParts['id'],'@type':this.viewingEntityIRIParts['entity']}}})
				}
			}
		},
		mounted()
		{
			if(this.hasFetchedInitialData)
			{
				this.checkRoutePermissions();
				this.ensureViewingEntity();
			}
		},
		watch:{
			hasFetchedInitialData(){
				this.checkRoutePermissions();
				this.ensureViewingEntity();
			},
			viewingEntityIRIParts:{
				deep: true,
				handler: function(to){
					this.ensureViewingEntity();
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.super-loader{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
</style>
